.connectWalletWrapper{
    position: relative;
    margin-left: 30px;
    display: flex;
    justify-content: right;
}
.connectWallet{
    background-color: black;
    border: 2px solid var( --main-green);
    color: var( --main-green);
    padding:  10px;
    border-radius: 5px;
    margin: 3px;
    cursor: pointer;
    font-weight: 600;
}

/* button */

  .connectWalletBtn{
    background:  black;
    border: 2px solid  var( --main-green);
    border-radius: 5px;
    color:  var( --main-green);
    padding: 10px 15px;
    position: relative;
    transition: all 0.5s;
    box-shadow: 6px 6px  black, 8px 8px;
    cursor: pointer;
    font-size: 18px !important;
    margin-bottom: 15px;
    font-family: BUBBLE;
  }
  
  .connectWalletBtn::after{
    position: absolute;
    content: "";
    transition: all 0.4s;
    top: 4px;
    right: -10px;
    height: 3px;
    width: 9px;
    transform: skewY(45deg);
    background-color:  var( --main-green);
  }
  
  .connectWalletBtn::before{
    position: absolute;
    content: "";
    transition: all 0.5s;
    bottom: -4px;
    left: -2px;
    height: 2px;
    width: 11px;
    transform: skewY(45deg);
    background-color:  var( --main-green);
  }

  .connectWalletBtn:hover {
    margin-bottom: 5px;
    margin-top: 10px;
    box-shadow: 0px 0px  black, 0px 0px;
  }
  .connectWalletBtn:hover::before {
    width: 0px;
    bottom: 0;
  }
  .connectWalletBtn:hover::after {
    width: 0px;
    right: -2px;
  }

/* end button */

.EthAlert{
    position: absolute;
    top: 0;
    left: 0;
    background: orange;
    width: 100%;
    color: white;
    padding: 0 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 8;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
    box-sizing: border-box;
}

.EthAlertOpen {
    height: 40px;
}

.EthAlert div{
    cursor: pointer;
}

@media (max-width: 600px) {
  .connectWalletBtn{
    transform: scale(0.8);
  }

  .connectWalletWrapper{
    margin-left: 0px;
  }

  .EthAlert{
    font-size: 15px;
  }
}