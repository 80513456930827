.disconnected{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.disconnected-text{
    color: var(--main-purple);
    text-align: center;
    font-size: 20px;
}

.disconnected img{
    height: 100px;
}