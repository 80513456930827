.catalog-wrapper{
    display: flex;
    min-height: 70vh;
}

.catalog__cards-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 40px;
    flex: 3;

}

@media (max-width: 760px) {
    .catalog-wrapper{
        flex-direction: column;
    }
}