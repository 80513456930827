.navbar{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
    font-size: 27px;
    align-items: center;
    text-align: center;
    max-width: 1200px;
    margin: auto;
}

.navbar > div:first-of-type{
    margin: auto;
}
.logo{
    height: 60px;
    width: auto;
}

.navbar__buttons{
    display: flex;
    justify-content:flex-end;
    height: 100%;
    align-items: center;
}


@media (max-width: 900px) {
    .navbar{
        max-width: 100%;    
        grid-template-columns: 1fr 1fr;
    }
    .navbar-fock{
        display: none;
    }

    /* .logo{
        height: 80px;
    } */
}