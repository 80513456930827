
.slick-slide img{
    margin: auto;
    width: 100vw;
}

.PrevArrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50% );
    left: 20px;
    background: url("../../assets/images/icons/arrow.png") no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 10;
}

.NextArrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50% ) rotate(180deg);
    right: 20px;
    background: url("../../assets/images/icons/arrow.png") no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 10;
}