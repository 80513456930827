body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  --main-green: #CCFFCC;
  --main-purple: #9900CC;
  --main-blue: #02feff;
  font-family: BAHNSCHRIFT;
}

html{
  overflow-x: hidden;
}
* {box-sizing: border-box;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: APPLE;
  src: url("./assets/fonts/APPLE.ttf");
}
@font-face {
  font-family: BAHNSCHRIFT;
  src: url("./assets/fonts/BAHNSCHRIFT.ttf");
}
@font-face {
  font-family: BUBBLE;
  src: url("./assets/fonts/Bubbleboddy.ttf");
}