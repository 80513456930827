.filter-wrapper{
  flex: 1;
  min-width: max-content;
  max-height: 50px;
  max-width: 200px;
}

.filter-wrapper > *{
  margin-top: 4px !important;
  cursor: pointer;
  user-select: none;
}

.filter-wrapper input[type="checkbox"] {
    -webkit-appearance:none;
    margin: 0;
    margin-right: 10px;

}

input[type='checkbox']:before {
    content:'';
    display:block;
    width:12px;
    height:12px;
    border: 1px solid var(--main-green);
    border-radius: 2px;    
  }

  input[type='checkbox']:checked:before {
    background: var(--main-green);
  }

  @media (max-width: 760px) {
    .filter-wrapper{
      margin-bottom: 60px;
    }
  }