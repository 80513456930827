
.healthpack-animationWrapper:before {
    content: url(../../../assets/images/animations/healthpack/1.png)
    url(../../../assets/images/animations/healthpack/2.png)
    url(../../../assets/images/animations/healthpack/3.png)
    url(../../../assets/images/animations/healthpack/4.png)
    url(../../../assets/images/animations/healthpack/button.png)
    url(../../../assets/images/animations/healthpack/buttonHover.png);
    visibility: hidden;
    position: absolute;
    left: -99999px;
    top: -99999px;
  }

.healthpack-animationWrapper{
    width: min(80vw, 1200px); 
    height: calc(1160 * min(80vw, 1200px) / 1920);
    background-image: url("../../../assets/images/animations/healthpack/1.png");
    background-size: contain;
    position: relative;
    margin:auto;
}
.healthpack-working{
    animation: healthpackProcess 0.8s infinite;
}
@keyframes healthpackProcess{
    0%,100% {
        background-image: url("../../../assets/images/animations/healthpack/1.png");
    }
    25% {
        background-image: url("../../../assets/images/animations/healthpack/2.png");
    }
    50% {
        background-image: url("../../../assets/images/animations/healthpack/3.png");
    }
    75% {
        background-image: url("../../../assets/images/animations/healthpack/4.png");
    }
}

.healthpack-button{
    border-radius: 40%;
    cursor: pointer;
    position: absolute;
    left: 55%;
    bottom: 29%;
    transform: translate(-50%, 50%);
    width: 10%;
    height: 7%;
    background-image: url("../../../assets/images/animations/healthpack/button.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.pressed{
    background-image: url("../../../assets/images/animations/healthpack/buttonHover.png");
    cursor: not-allowed;

}

.healthpack-button:hover{
    background-image: url("../../../assets/images/animations/healthpack/buttonHover.png");
}

.healthpack-coin{
    position: absolute;
    left: 50.7%;
    bottom: 19.8%;
    transform: translate(-50%, 50%);
    width: 4%;
    height: 4%;
    background-image: url("../../../assets/images/general/coin.gif");
    background-size: contain;
    background-repeat: no-repeat;
}

.healthpack-item{
    background-color: black;
    border-radius: 5%;
    position: absolute;
    left: 47%;
    bottom: 51.5%;
    transform: translate(-50%, 50%);
    width: 7%;
    height: 11.5%;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.healthpack-alien{
    background-color: black;
    border-radius: 5%;
    cursor: pointer;
    position: absolute;
    left: 59.70%;
    bottom: 51.2%;
    transform: translate(-50%, 50%);
    width: 7%;
    height: 11.5%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 6%;
    overflow: hidden;
}

@media (max-width: 720px){
   

.healthpack-animationWrapper:before {
    content: url(../../../assets/images/animations/healthpack/mobile/1.png)
    url(../../../assets/images/animations/healthpack/mobile/2.png)
    url(../../../assets/images/animations/healthpack/mobile/3.png)
    url(../../../assets/images/animations/healthpack/mobile/4.png)
    url(../../../assets/images/animations/healthpack/mobile/button.png)
    url(../../../assets/images/animations/healthpack/mobile/buttonHover.png);
    visibility: hidden;
    position: absolute;
    left: -99999px;
    top: -99999px;
  }

    .healthpack-animationWrapper{
        width: min(80vw, 1200px); 
        height: calc(700 * min(80vw, 572px) / 508);
        background-image: url("../../../assets/images/animations/healthpack/mobile/1.png");
        background-repeat: no-repeat;
    }
    .healthpack-alien{
        height: 24.9%;
        width: 34.5%;
        left: 76%;
        bottom: 43.8%;
    }
 
    .healthpack-button{
        left: 21%;
        bottom: 12%;
        width: 21%;
        height: 15%;
        border-radius: 0;
        background-image: url("../../../assets/images/animations/healthpack/mobile/button.png");
    }
    .healthpack-button:hover{
        background-image: url("../../../assets/images/animations/healthpack/mobile/buttonHover.png");
    }
    @keyframes healthpackProcess{
        0%,100% {
            background-image: url("../../../assets/images/animations/healthpack/mobile/1.png");
        }
        25% {
            background-image: url("../../../assets/images/animations/healthpack/mobile/2.png");
        }
        50% {
            background-image: url("../../../assets/images/animations/healthpack/mobile/3.png");
        }
        75% {
            background-image: url("../../../assets/images/animations/healthpack/mobile/4.png");
        }
    }
    
    .healthpack-coin{
        display: none;
    }

    .healthpack-item{
        display: none;
    }
}