.modalAliensOverlay{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.906);
    z-index: 5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.modalAliensContainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
.modalAliens{
    background-color: black;
    padding: 20px;
    width: 600px;
    max-width: 80vw;
    font-size: 20px;
    color: var(--main-green);
    border: 2px solid var(--main-green);
    border-radius: 20px;
}
.modalAliensBorder{
    border: 2px solid var(--main-green);
    border-radius: 20px 25px 20px 25px;
    position: absolute;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    top: 3px;
    left: 3px;
    z-index: -1;
}
.modalAliensClose{
    border: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    color: var(--main-green);
    font-weight: 800;
    font-size: 20px;
    cursor: pointer;
}
.modalAliensTitle{
    margin-inline: auto;
    width: fit-content;
    font-weight: 300;
    font-family: BUBBLE;
}
.modalAliensTitle a {
    color: var(--main-green) !important;
}
.modalAlienCards{
    padding: 0 10px;
    grid-gap: 30px;
    display: grid;
    height: max(50vh, 300px);
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    overflow: hidden auto;
    
}
.modalAlienCard{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-self: flex-start;
}
.modalAlienCard img{
    width: 100%;
}
/* scrollbar */
.modalAlienCards::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.modalAlienCards::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
.modalAlienCards::-webkit-scrollbar-thumb {
    background: var(--main-green);
    border:none;
}
.modalAlienCards::-webkit-scrollbar-track {
    background: #000000;
    border: 1px solid var(--main-green);
    border-radius: 0px;
}
.modalAlienCards::-webkit-scrollbar-corner {
    background: transparent;
}

.spinner-container-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-modal {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: var(--main-green);
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
  