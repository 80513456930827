.redeemModal-Wrapper{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.906);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.redeemModal-title{
    color: var(--main-purple);
}

.redeemModal-Modal{
    box-shadow: 0px 0px 40px -10px var(--main-purple), inset 0px 0px 40px -10px var(--main-purple);
    min-height: 150px;
    padding: 20px;
    width: 520px;
    font-size: 20px;
    color: var(--main-purple);
    border: 2px solid var(--main-purple);
    border-radius: 20px;
}

.redeemModal-content{
    padding: 40px;
    display: flex;
    justify-content: space-between;
}
.redeemModal-image{
    max-width: 40%;
    max-height: 200px;
}
.redeemModal-info{
    color: var(--main-green);
}
.redeemModal-info h3{
    margin-top: 0;
    margin-bottom: 20px;
}


.redeemModal-info > *{
text-align: right;
margin-bottom: 10px;
}

.redeemModal-ButtonWrapper{
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}


.redeemModal-button {
    flex: 1;
    margin: 10px;
    background-color: var(--main-purple);
    color: var(--main-green);
    border: 1px solid var(--main-purple);
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}
.redeemModal-disabled{
    background-color: gray !important;
    border: 1px solid gray !important;
    color: white !important;
}

.redeemModal-cancel{
    flex: 1;
    margin: 10px;
    background-color: black;
    color: var(--main-green);
    border: 1px solid var(--main-purple);
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}

.redeemModal-Footer{
    font-size: 15px;
}

@media (max-width: 700px){
    .redeemModal-Modal{
        max-width: 80vw;
        padding: 20px;
        box-sizing: border-box;
    }

    .redeemModal-Modal > div{
        padding: 10px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .redeemModal-image{
        max-width: 80%;
        max-height: 450px;
    }

    .redeemModal-Footer{
        width: max-content;
        margin-right: auto;
        margin-top: 20px;
    }

    .redeemModal-info{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}