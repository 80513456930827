
.footer{
    display: flex;
    justify-content: space-around;
    margin: auto;
    max-width: 200px;
    padding-bottom: 30px;
    align-items: center;
}

.footer img{
    max-height: 20px;
    max-width: 20px;
}
