
.soap-animationWrapper:before {
    content: url(../../../assets/images/animations/soap/1.png)
    url(../../../assets/images/animations/soap/2.png)
    url(../../../assets/images/animations/soap/3.png)
    url(../../../assets/images/animations/soap/4.png)
    url(../../../assets/images/animations/soap/5.png)
    url(../../../assets/images/animations/soap/button.png)
    url(../../../assets/images/animations/soap/buttonHover.png);
    visibility: hidden;
    position: absolute;
    left: -99999px;
    top: -99999px;  }

.soap-animationWrapper{
    width: min(80vw, 1200px); 
    height: calc(1160 * min(80vw, 1200px) / 1920);
    background-image: url("../../../assets/images/animations/soap/1.png");
    background-size: contain;
    position: relative;
    margin:auto;
}
.soap-working{
    animation: soapProcess 0.8s infinite;
}
@keyframes soapProcess{
    0%,100% {
        background-image: url("../../../assets/images/animations/soap/2.png");
    }
    25% {
        background-image: url("../../../assets/images/animations/soap/3.png");
    }
    50% {
        background-image: url("../../../assets/images/animations/soap/4.png");
    }
    75% {
        background-image: url("../../../assets/images/animations/soap/5.png");
    }
}

.soap-button{
    border-radius: 40%;
    cursor: pointer;
    position: absolute;
    right: 26.7%;
    top: 39%;
    transform: translate(-50%, 50%);
    width: 8%;
    height: 13%;
    background-image: url("../../../assets/images/animations/soap/button.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.soap-pressed{
    background-image: url("../../../assets/images/animations/soap/buttonHover.png");
    cursor: not-allowed;

}

.soap-button:hover{
    background-image: url("../../../assets/images/animations/soap/buttonHover.png");

}

.soap-item{
    background-color: black;
    border-radius: 5%;
    position: absolute;
    left: 32%;
    top: 11.8%;
    transform: translate(-50%, 50%);
    width: 9%;
    height: 14.5%;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.soap-alien{
    background-color: black;
    border-radius: 5%;
    cursor: pointer;
    position: absolute;
    left: 49.7%;
    bottom: 42.1%;
    transform: translate(-50%, 50%);
    width: 11%;
    height: 11.5%;
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
}

.soap-alien-washing> img{
    animation: alien-washing 1.5s infinite;
}

@keyframes alien-washing{
    0%,100% {
        rotate: 0;
    }
    50% {
        rotate: 360deg;
    }
}

.soap-alien-listener{
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    left: 49.7%;
    bottom: 39.1%;
    transform: translate(-50%, 50%);
    width: 11%;
    height: 18.5%;
}
.soap-alien-listener-pressed{
    cursor: not-allowed;
}

@media (max-width: 720px){
   

.soap-animationWrapper:before {
    content: url(../../../assets/images/animations/soap/mobile/1.png)
    url(../../../assets/images/animations/soap/mobile/2.png)
    url(../../../assets/images/animations/soap/mobile/3.png)
    url(../../../assets/images/animations/soap/mobile/4.png)
    url(../../../assets/images/animations/soap/mobile/button.png)
    url(../../../assets/images/animations/soap/mobile/buttonHover.png);
    visibility: hidden;
    position: absolute;
    left: -99999px;
    top: -99999px;  
    }

    .soap-animationWrapper{
        width: min(80vw, 1200px); 
        height: calc(700 * min(80vw, 1200px) / 700);
        background-image: url("../../../assets/images/animations/soap/mobile/1.png");
        background-repeat: no-repeat;
    }
    .soap-alien{
        height: 29%;
        width: 29%;
        left: 61.8%;
        bottom: 63.5%;
        z-index: 1;
        border-radius: 7%;
        overflow: hidden;
    }
    .soap-alien-listener{
        height: 29%;
        width: 29%;
        left: 61.8%;
        bottom: 63.5%;
        z-index: 2;

    }
    .soap-button{
        left: 21.7%;
        top: 55%;
        width: 19%;
        height: 21%;
        border-radius: 0;
        background-image: url("../../../assets/images/animations/soap/mobile/button.png");
    }
    .soap-button:hover{
        background-image: url("../../../assets/images/animations/soap/mobile/buttonHover.png");
    }
    @keyframes soapProcess{
        0%,100% {
            background-image: url("../../../assets/images/animations/soap/mobile/1.png");
        }
        25% {
            background-image: url("../../../assets/images/animations/soap/mobile/2.png");
        }
        50% {
            background-image: url("../../../assets/images/animations/soap/mobile/3.png");
        }
        75% {
            background-image: url("../../../assets/images/animations/soap/mobile/4.png");
        }
    }
    

    .soap-item{
        display: none;
    }

    .soap-alien-washing> img{
        animation: none;
    }
    :root:before {
        content: url(../../../assets/images/animations/soap/mobile/1.png)
        url(../../../assets/images/animations/soap/mobile/2.png)
        url(../../../assets/images/animations/soap/mobile/3.png)
        url(../../../assets/images/animations/soap/mobile/4.png)
        url(../../../assets/images/animations/soap/mobile/button.png)
        url(../../../assets/images/animations/soap/mobile/buttonHover.png);
        visibility: hidden;
        position: absolute;
        left: -999em;
      }
}