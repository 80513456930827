.App {
  color: var(--main-green);
  max-width: 1200px;
  min-height: calc(100vh - 360px);
  margin: auto;
  margin-bottom: 40px;
}

@media (max-width: 1200px) {
  .App{
    max-width: 85vw;
    min-height: calc(100vh - 320px);
  }
}

