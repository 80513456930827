
.eyedrop-animationWrapper:before {
    content: url(../../../assets/images/animations/eyedrop/1.png)
    url(../../../assets/images/animations/eyedrop/2.png)
    url(../../../assets/images/animations/eyedrop/3.png)
    url(../../../assets/images/animations/eyedrop/4.png)
    url(../../../assets/images/animations/eyedrop/button.png)
    url(../../../assets/images/animations/eyedrop/buttonHover.png);
    visibility: hidden;
    position: absolute;
    left: -99999px;
    top: -99999px;

  }

.eyedrop-animationWrapper{
    width: min(80vw, 1200px); 
    height: calc(1160 * min(80vw, 1200px) / 1920);
    background-image: url("../../../assets/images/animations/eyedrop/1.png");
    background-size: contain;
    position: relative;
    margin:auto;
}
.eyedrop-working{
    animation: eyedropProcess 0.8s infinite;
}
@keyframes eyedropProcess{
    0%,100% {
        background-image: url("../../../assets/images/animations/eyedrop/1.png");
    }
    25% {
        background-image: url("../../../assets/images/animations/eyedrop/2.png");
    }
    50% {
        background-image: url("../../../assets/images/animations/eyedrop/3.png");
    }
    75% {
        background-image: url("../../../assets/images/animations/eyedrop/4.png");
    }
}

.eyedrop-button{
    border-radius: 40%;
    cursor: pointer;
    position: absolute;
    right: 13%;
    bottom: 31%;
    transform: translate(-50%, 50%);
    width: 7%;
    height: 12%;
    background-image: url("../../../assets/images/animations/eyedrop/button.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.eyedrop-pressed{
    background-image: url("../../../assets/images/animations/eyedrop/buttonHover.png");
    cursor: not-allowed;

}

.eyedrop-button:hover{
    background-image: url("../../../assets/images/animations/eyedrop/buttonHover.png");

}

.eyedrop-coin{
    position: absolute;
    left: 50.7%;
    bottom: 19.8%;
    transform: translate(-50%, 50%);
    width: 4%;
    height: 4%;
    background-image: url("../../../assets/images/general/coin.gif");
    background-size: contain;
    background-repeat: no-repeat;
}

.eyedrop-alien{
    overflow: hidden;
    border-radius: 5%;
    cursor: pointer;
    position: absolute;
    left: 29.7%;
    bottom: 58.4%;
    transform: translate(-50%, 50%);
    width: 7.5%;
    height: 12.5%;
    background-size: contain;
    background-repeat: no-repeat;
}
.eyedrop-alien-pressed{
    cursor: not-allowed;
}


@media (max-width: 720px){
   
    .eyedrop-animationWrapper:before {
        content: url(../../../assets/images/animations/eyedrop/mobile/1.png)
        url(../../../assets/images/animations/eyedrop/mobile/2.png)
        url(../../../assets/images/animations/eyedrop/mobile/3.png)
        url(../../../assets/images/animations/eyedrop/mobile/4.png)
        url(../../../assets/images/animations/eyedrop/mobile/button.png)
        url(../../../assets/images/animations/eyedrop/mobile/buttonHover.png);
        visibility: hidden;
        position: absolute;
        left: -99999px;
        top: -99999px;
    
      }
    .eyedrop-animationWrapper{
        width: min(84vw, 1200px); 
        height: calc(606 * min(80vw, 1200px) / 474);
        background-image: url("../../../assets/images/animations/eyedrop/mobile/1.png");
        background-repeat: no-repeat;
    }
    .eyedrop-alien{
        left: 66.5%;
        bottom: 54.5%;
        width: 28%;
        height: 24%;
        z-index: 1;
        border-radius: 4%;
        overflow: hidden;
    }
   
    .eyedrop-button{
        left: 22%;
        top: 61%;
        width: 20%;
        height: 18%;
        border-radius: 0;
        background-image: url("../../../assets/images/animations/eyedrop/mobile/button.png");
    }
    .eyedrop-button:hover{
        background-image: url("../../../assets/images/animations/eyedrop/mobile/buttonHover.png");
    }
    @keyframes eyedropProcess{
        0%,100% {
            background-image: url("../../../assets/images/animations/eyedrop/mobile/1.png");
        }
        25% {
            background-image: url("../../../assets/images/animations/eyedrop/mobile/2.png");
        }
        50% {
            background-image: url("../../../assets/images/animations/eyedrop/mobile/3.png");
        }
        75% {
            background-image: url("../../../assets/images/animations/eyedrop/mobile/4.png");
        }
    }
    

    .eyedrop-item{
        display: none;
    }

    .eyedrop-alien-washing> img{
        animation: none;
    }
    :root:before {
        content: url(../../../assets/images/animations/eyedrop/mobile/1.png)
        url(../../../assets/images/animations/eyedrop/mobile/2.png)
        url(../../../assets/images/animations/eyedrop/mobile/3.png)
        url(../../../assets/images/animations/eyedrop/mobile/4.png)
        url(../../../assets/images/animations/eyedrop/mobile/button.png)
        url(../../../assets/images/animations/eyedrop/mobile/buttonHover.png);
        visibility: hidden;
        position: absolute;
        left: -999em;
      }
}