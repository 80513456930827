.inventory__card-button{
    width: 100%;
    padding: 12px;
    background-color: transparent;
    outline: none;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
}

.emptyInventory_filter{
    height: min-content;
    margin: 0 auto;
}