.activity-item{
    display: flex;
    margin-top: 40px;
    color:white;
    word-break: break-word;
}
.activity-date{
    width: 110px;
    text-align: right;
    color: white;
    margin-top: 50px;
}
.activity-item-time{
    min-width: 110px;
    text-align: right;
    margin-right: 30px;
}