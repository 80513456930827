.card{
    max-height: 320px;
    display: flex;
    flex-direction: column;
    border: 2px solid white;
    border-radius: 20px;
    position: relative;
    animation-name: opacity;
    animation-duration: 1.5s;
    cursor: pointer;
    transition: .3s;
}

.card:hover{
    transform: scale(1.05);
}

.card-bagHead{
cursor:initial;
}

.card-bagHead:hover{
    transform: none;
}

@keyframes opacity {
    from {opacity: 0;}
    to {opacity: 1;}
  }

.card__community{
    position: absolute;
    right: 10px;
    top: 10px;
}

.card__img-wrapper{
    min-height:170px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card__img-wrapper img{
    max-width: 120px;
    max-height: 120px;
}

.card hr{
    height: 1px; 
    border: 0;
    width: 100%;
}

.card__content{
    margin: auto 0;
    padding: 20px;
}