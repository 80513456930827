.menu{
    margin-left: auto;
    position: relative;
}
.menu__img{
    cursor: pointer;
    height: 50px;
    z-index: 3;
    position: relative;
}
.menu_background{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
}

.menu__links-wrapper{
    border: 1px solid var(--main-green);
    border-radius: 20px;
    position: absolute;
    background: black;
    z-index: 3;
    padding: 10px;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
}

.menu__links-wrapper > *{
 display: block;
 color: var(--main-green);
 font-size: 16px;
 text-align: left;
 text-decoration: none;
 margin-top: 4px;
}