.polaroid-back{
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.546);
    z-index: 2;
    position: fixed;
    z-index: 3;
    animation-name: polaroidback;
    animation-duration: 1s;
}

.polaroid-wrapper{
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translate(-50%, -50%) rotate(-5deg);
    background-color: white;
    text-align: center;
    box-shadow: 15px 8px 15px rgba(14, 14, 14, 0.426);
    animation-name: polaroid;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    padding: 20px;
    padding-bottom: 50px;
    animation-fill-mode: forwards;  
    width: 200px;
    height: 200px;
    box-sizing: content-box;
}

.polaroid-img{
    max-width: 200px;
    max-height: 200px;
    box-sizing: content-box;
    background-color: #262525;
}
.polaroid-text{
    content: "CURED";
    color: red;
    margin-top: 10px ;
}

.polaroid-continue{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 15%;
    color: var(--main-blue);
    text-shadow: 0px 0px 10px #068888;
    font-size: 20px;
    opacity: 0;
    animation-name: continue;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-delay: 3s;
    cursor: pointer;
}
@keyframes continue{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }}

.polaroid-wrapper::after{
    content: "";
    background-color: black;
    width: calc(100% - 40px);
    height: calc(100% - 70px);
    position: absolute;
    left: 20px;
    top: 20px;
    animation-duration: 1s;
    animation-fill-mode: forwards;  
    animation-name: polaroidafter;
    animation-timing-function: ease-in-out;
    animation-delay: 2s;
}

.polaroid-back-hide{
    animation-name:hide ;
    animation-duration: 1s;
    animation-fill-mode: forwards;  
}

@keyframes hide{
    100%{
        opacity: 0;
    }
}
@keyframes polaroid{
    0%{
    top: -50%;
    transform: translate(-50%, -50%) rotate(0deg);
    }
    50%{
        transform: translate(-50%, -50%) rotate(0deg);
        }
    100%{
        top: 50%;
        transform: translate(-50%, -50%) rotate(-5deg);
    }
}

@keyframes polaroidback{
    0%{
        background-color: rgba(0, 0, 0, 0);

    }
    100%{
        background-color: rgba(0, 0, 0, 0.546);
    }
}

@keyframes polaroidafter{
    0%{
        background-color: black;

    }
    100%{
        background-color: transparent;
    }
}