.purchaseModal-Wrapper{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.906);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.purchaseModal-title{
    color: var(--main-purple);
}
.purchaseModal-community{
    position: absolute;
    right: 20px;
    top: 20px;
}
.purchaseModal-Modal{
    min-height: 150px;
    padding: 20px;
    width: 520px;
    font-size: 20px;
    border-radius: 20px;
    position: relative;
}

.purchaseModal-content{
    padding: 40px;
    display: flex;
    justify-content: space-between;
}
.purchaseModal-image-wrapper{
    width: 50%;
    display: flex;
    justify-content: center;
    max-height: 200px;

}
.purchaseModal-image{
    max-width: 100%;
    max-height: 200px;
    align-self: center;
}

.purchaseModal-info h3{
    margin-top: 0;
    margin-bottom: 15px
}
.purchaseModal-info > * {
    text-align: right;
    justify-content: flex-end;
    margin-bottom: 5px

}
.purchaseModal-info{
    color: var(--main-green);
}

.purchaseModal-ButtonWrapper{
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.purchaseModal-warning{
    color: var(--main-green);
    font-size: 15px;
    padding:0 40px;
    padding-bottom: 10px;
}
.purchaseModal-warningText{
    text-align: justify;
}

.purchaseModal-button {
    flex: 1;
    margin: 10px;
    background-color: var(--main-purple);
    color: var(--main-green);
    border: 1px solid var(--main-purple);
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3;
}

.purchaseModal-button:disabled{
    background-color: gray;
    color: rgb(22, 22, 22);
    border-color: gray;
    cursor: not-allowed;
}

.purchaseModal-cancel{
    flex: 1;
    margin: 10px;
    background-color: black;
    color: var(--main-green);
    border: 1px solid var(--main-purple);
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}

.purchaseModal-Footer{
    font-size: 15px;
}

.purchaseModal-checkboxWrapper{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.purchaseModal-checkbox {
    -webkit-appearance:none;
    margin: 0;
    margin-right: 10px;
    cursor: pointer;
}

.purchaseModal-checkbox:before {
    content:'';
    display:block;
    width:12px;
    height:12px;
    border: 1px solid var(--main-green);
    border-radius: 2px;    
  }
.purchaseModal-link{
    text-decoration: underline;
    color: var(--main-purple);
}
@media (max-width: 700px){
    .purchaseModal-Modal{
        max-width: 95vw;
        padding: 20px;
        box-sizing: border-box;
    }

    .purchaseModal-Modal > div{
        padding: 10px;
        flex-direction: column;
        text-align: center;
    }
    .purchaseModal-image-wrapper{
        width: 80%;
        display: flex;
        justify-content: center;
        max-height: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .purchaseModal-image{
        max-width: 100%;
        max-height: 200px;
        align-self: center;
    }

    .purchaseModal-info{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .purchaseModal-warningText{
        font-size: 10px;
    }

    .purchaseModal-Footer{
        width: max-content;
        margin-right: auto;
    }

}