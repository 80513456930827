.modalmessage-Wrapper{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.906);
    z-index: 5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.modalmessage-Content{
    word-break: break-word;
    min-height: 150px;
    width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 30vh;
    font-size: 20px;
    background-color: black;
    color: var(--main-purple);
    border: 2px solid var(--main-purple);
    border-radius: 20px;
    box-shadow: 0px 0px 40px -10px var(--main-purple), inset 0px 0px 40px -10px var(--main-purple);
}

.modalmessage-ButtonWrapper{
   width: 450px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.modalmessage-button {
    margin: 10px;
    background-color: var(--main-purple);
    color: black;
    border: 1px solid var(--main-purple);
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width: 700px){
    .modalmessage-Content{
        max-width: 90vw;
        padding: 20px;
        box-sizing: border-box;
    }
    .modalmessage-ButtonWrapper{
        max-width: 90vw;
    }
}