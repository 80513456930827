.line{
    width: 100vw;
    height: 1px;
    background: linear-gradient(90deg, var(--main-green) , var(--main-purple));
    margin-bottom: 40px;
    max-width:100%;  
}

.line-info{
    align-items: center;
    display: flex;
    padding: 0 20px;
    max-width: 1200px;
    margin:auto;
}

.line-fock{
    display: none;
}

.line__pathname{
    text-align: right;
    color: var(--main-green);
    font-size: 20px;
    text-transform: uppercase;
    margin: auto;
    margin-right: 0;
    box-sizing: border-box;
    padding: 20px 0;
}

@media (max-width: 900px){
  
    .line-fock{
        display: block;
        padding: 20px 0;
    }

}