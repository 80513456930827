.details__item-wrapper{
    background-size: cover;
    background-attachment: fixed;
}

.reverse{
    direction : rtl; 
}

.details__item{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: center;
    max-width: 100%;
    width: 700px;
    margin: 20px auto;
}

.details__item-img-wrapper{
    min-width: 180px;
    min-height: 180px;
    background: radial-gradient(circle at center, var(--main-green) -50%, transparent 70%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.details__item-img-wrapper img{
    max-width: 120px;
    max-height: 120px;
}

.reverse .details__item-line{
    transform: scaleX(-1);
}

.reverse .details__item-text{
    direction : initial; 
    text-align: right;
}

.details__item-text{
    padding: 0 20px;
    word-break: break-word;

}

@media (max-width: 720px) {
 .details__item-line{
    width: 100%;
}
.details__item-img-wrapper{
    min-width: 100px;
    min-height: 100px;
}
 .details__item-img-wrapper img{
    max-width: 100px;
    max-height: 100px;
}
.details__item-text {
    padding: 0 10px;
}
}