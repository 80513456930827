.spinner{
    background-color: black;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: .3s;
}
.hideSpinner{
    animation: hideSpinnerAnimation 1s ;
    animation-fill-mode: forwards;  
}
.spinner img{
    width: 100px;
}

@keyframes hideSpinnerAnimation{
    0% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100%{
        opacity: 0;
        z-index: -10;
    }
}