.trasnfer{
    position: relative;
    font-family: APPLE;
    overflow: hidden;
}

.trasnfer > div{
    position: absolute;
    color: #04f4f4;
}
.trasnfer input{
    color: #04f4f4;
    background: transparent;
    width: 100%;
    outline: none;
    border: 0;
    border-bottom: 2px solid #04f4f4;
    border-style: dotted;
    font-family: APPLE;
    font-size: calc((100vw * 30) / 2560);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.transfer__text{
    left: 10%;
    top: 24%;
    font-size: calc((100vw * 30) / 2560);
    width: 60%;
}

.transfer__text div{
   margin: 10px 0;
}
.transfer__machine-desktop{
    position: relative;
    width: 100%;
}
.transfer__machine-mobile{
    position: relative;
    width: 100%;
    display: none;
}

.transfer__machine-press{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.transfer_spinner{
    left: 40%;
    top: 47%;
    position: absolute;
    transform: translate(-50%, -50%);
}


.transfer__button{
    position: absolute;
    cursor: pointer;
    bottom: 7.66%;
    width: 14%;
    height: 9.33%;
    left: 50%;
    transform: translateY(50%);
    background-image: url("../../assets/images/animations/transfer/transferButton.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.transfer__button:hover{
    background-image: url("../../assets/images/animations/transfer/transferButtonClick.png");
}

.transfer__button-newtransfer{
    background-image: url("../../assets/images/animations/transfer/newTransfer.png");
}

.transfer__button-newtransfer:hover{
    background-image: url("../../assets/images/animations/transfer/newTransferClick.png");
}

.transfer-message{
    line-height: 30px;
    width: 90%;
    white-space: pre-line;
}
@media(max-width: 700px){
    
    .transfer__machine-desktop{
        display: none;
    }

    .transfer__machine-mobile{
        display: block;
    }

    .transfer__button{
        width: 33%;
        height: 8%;
        left: 60%;
        bottom: 9.66%;
    }

    .transfer__machine-press{
        left: -15%;
        top: 31%;
        width: 159%;
    }

    .transfer__text{
        font-size: calc(100vw / 50);
        width: 80%;
    }

    .transfer__fockInput{
        margin: 10px 0; 
    }

    .transfer_spinner{
        left: 49%;
        top: 44%;
    }
    
    .transfer-message{
        line-height: 20px;
        width: 100%;
    }
}